.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* App.css */

/* Add this style to create a single-pixel border around buttons */
button {
  border: 1px solid #000; /* You can customize the color by changing #000 to your desired color code */
  border-radius: 4px;
  padding: 8px 16px; /* Adjust padding as needed */
  margin: 4px; /* Adjust margin as needed */
}

/* Add this style to remove default button styles */
button:focus {
  outline: none;
}

/* Additional style for the form input */
input {
  padding: 8px;
  margin: 4px;
}

/* App.css */
nav {
  background-color: #333; /* Set background color of the navbar */
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li:hover {
  background-color: #555; /* Change background color on hover */
}
